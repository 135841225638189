import { Card, Typography, Skeleton } from 'antd';
import { RefreshTokenMetadataWithUserId } from '../../../api/user/domain/users';
import { SessionTable } from '../../session/components/SessionTable';
import { useTerminateUserSession } from '../../session/hooks/useTerminateUserSession';
import { useUserSessions } from '../../session/hooks/useUserSessions';
import { useDocumentTitle } from '../../shared/hooks/useDocumentTitle';

export const SessionAdministration = () => {
  const sessions = useUserSessions();
  const terminate = useTerminateUserSession();
  useDocumentTitle('Session Administration');

  return (
    <Card title={<Typography.Title level={4}>Session Administration</Typography.Title>}>
      {sessions.isLoading ? (
        <Skeleton />
      ) : (
        <SessionTable
          sessions={sessions.data || []}
          terminate={(session) => {
            const id = session as RefreshTokenMetadataWithUserId;
            return terminate.mutateAsync([id.userid, session.tokenFamily]);
          }}
        />
      )}
    </Card>
  );
};
