import { Badge, Col, Form, FormInstance, Row, Typography } from 'antd';
import { ShiftedDrawer } from '../../../../shared/components/ShiftedDrawer';
import { useEffect, useState, Fragment } from 'react';
import { ReleaseNotesValidator } from '../../../../../domain';
import { MarkdownFormItem } from '../../../../shared/components/MarkdownFormItem';
import { RELEASE_NOTES_PLACEHOLDER } from '../../../../../constants/texts';
import useEnhanceSoftwareItemVersionReleaseNotes from './hooks/useEnhanceSoftwareItemVersionReleaseNotes';
import useEnhanceConfigurationVersionReleaseNotes from './hooks/useEnhanceConfigurationVersionReleaseNotes';
import EnhanceReleaseNotesButton from './EnhanceReleaseNotesButton';
import styled from 'styled-components';
import { useStyles } from '@pacts-plugin-api';
import useSubmitMetric from '../../../../metrics/hooks/useMetrics';
import { MetricClickIds } from '../../../../metrics/constants';
import type { Project, SoftwareApp, SoftwareAppVersion, Tool, ToolVersion } from '../../../../../api/engineering/domain/types';

type EnhanceReleaseNotesProps = {
  originalReleaseNotes: string;
  onOk: (enhancedReleaseNotes: string) => void;
  bundleId?: string;
  project?: Project;
  configId?: string;
  commonSoftwareAppVersionIds?: number[];
  projectSoftwareAppVersionIds?: number[];
  toolVersionIds?: number[];
  originalForm?: FormInstance;
  disabled?: boolean;
} & (
  { type: 'tool'; context: Tool; version?: ToolVersion } |
  { type: 'app'; context: SoftwareApp; version?: SoftwareAppVersion } |
  { type: 'project'; }
);

interface LabelProps {
  padding: number;
}

const StyledEnhancedReleaseNotesLabel = styled(Typography.Text)<LabelProps>`
  padding-left: ${(props) => `${props.padding}px`};
`;

const StyledMarkdownFormItem = styled(MarkdownFormItem)`
  margin-top: 0;
`;

export const EnhanceReleaseNotes = (props: EnhanceReleaseNotesProps) => {
  const { originalReleaseNotes,
    onOk,
    bundleId,
    project,
    configId,
    commonSoftwareAppVersionIds,
    projectSoftwareAppVersionIds,
    toolVersionIds,
    originalForm,
    disabled
  } = props;
  const [drawerVisible, setDrawerVisible] = useState(false);
  const enhanceSoftwareItemVersionReleaseNotes = useEnhanceSoftwareItemVersionReleaseNotes();
  const enhanceConfigurationVersionReleaseNotes = useEnhanceConfigurationVersionReleaseNotes();
  const enhanceReleaseNotes = project ? enhanceConfigurationVersionReleaseNotes : enhanceSoftwareItemVersionReleaseNotes;
  const [form] = Form.useForm();
  const isEnhancingConfigurationVersionReleaseNotes =
    project !== undefined &&
    bundleId !== undefined &&
    configId !== undefined &&
    commonSoftwareAppVersionIds !== undefined &&
    projectSoftwareAppVersionIds !== undefined &&
    toolVersionIds !== undefined;
  const metrics = useSubmitMetric();
  const token = useStyles();
  const [wasGenerated, setWasGenerated] = useState(false);

  const submitMetric = (operationId: MetricClickIds) => {
    if (props.type === 'tool' && props.version) {
      metrics.submitClick({ operationId }).withToolVersionContext(props.context, props.version);
    } else if (props.type === 'tool') {
      metrics.submitClick({ operationId }).withToolContext(props.context);
    } else if (props.type === 'app' && props.project && props.version) {
      metrics.submitClick({ operationId }).withAppVersionContext(props.context, props.version, props.project);
    } else if (props.type === 'app' && props.project) {
      metrics.submitClick({ operationId }).withAppContext(props.context, props.project);
    } else if (props.type === 'app' && props.version) { 
      metrics.submitClick({ operationId }).withCommonAppVersionContext(props.context, props.version);
    } else if (props.type === 'app') {
      metrics.submitClick({ operationId }).withCommonAppContext(props.context);
    } else if (props.type === 'project' && props.project) {
      metrics.submitClick({ operationId }).withProjectContext(props.project);
    } else {
      metrics.submitClick({ operationId }).withoutContext();
    }
  }

  /**
   * API call for enhancing config/software item version release notes
   */
  const handleEnhanceReleaseNotes = (regenerate?: boolean) => {    
    submitMetric(regenerate ? MetricClickIds.genAIRegenerateReleaseNotes : MetricClickIds.genAIGenerateReleaseNotes);

    // Enhance config version release notes
    if (isEnhancingConfigurationVersionReleaseNotes) {
      enhanceConfigurationVersionReleaseNotes.mutate([
        +bundleId,
        +project.idProject,
        +configId,
        form.getFieldValue('releaseNotes') || null,
        commonSoftwareAppVersionIds,
        projectSoftwareAppVersionIds,
        toolVersionIds
      ]);
      setWasGenerated(true);
    } else {
      // Enhance software item version release notes
      form
        .validateFields()
        .then(() => {
          enhanceSoftwareItemVersionReleaseNotes.mutate(form.getFieldValue('releaseNotes'));
          setWasGenerated(true);
        })
        .catch(() => {});
    }
  };

  /**
   * Set original release notes fields from props and call enhance API on load
   */
  useEffect(() => {
    if (drawerVisible) {
      form.setFieldsValue({ releaseNotes: originalReleaseNotes });
      handleEnhanceReleaseNotes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalReleaseNotes, drawerVisible]);

  /**
   * Set enhanced release notes field once API call is done
   */
  useEffect(() => {
    if (drawerVisible) {
      const { releaseNotes } = enhanceReleaseNotes.data || {};

      if (releaseNotes) {
        form.setFieldValue('enhancedReleaseNotes', releaseNotes);
      }
    }
  }, [enhanceReleaseNotes.data, form, drawerVisible]);

  const handleReplaceReleaseNotes = () => {
    onOk(enhanceReleaseNotes.data?.releaseNotes || '');

    form.resetFields(['enhancedReleaseNotes']);
    enhanceReleaseNotes.reset();

    submitMetric(MetricClickIds.genAIReplaceOriginalText);
    setDrawerVisible(false);
  };

  const handleCloseDrawer = () => {
    form.resetFields(['enhancedReleaseNotes']);
    enhanceReleaseNotes.reset();

    submitMetric(MetricClickIds.genAICancelEnhancement);
    setDrawerVisible(false);
  };

  const openEnhanceDrawer = () => {
    if (!isEnhancingConfigurationVersionReleaseNotes) {
      originalForm
        ?.validateFields(['releaseNotes'])
        .then(() => {
          setDrawerVisible(true);
        })
        .catch(() => null);
    } else {
      setDrawerVisible(true);
    }
  };

  // if the original form prop is given, we assume the button is inside a form element
  // hence wrap it in a Form.Item to preserve consistent spacing within the form
  const ButtonWrapper = !!originalForm ? Form.Item : Fragment;

  return (
    <>
      <ButtonWrapper wrapperCol={{ span: 24 }}>
        <EnhanceReleaseNotesButton
          text={!isEnhancingConfigurationVersionReleaseNotes ? 'Enhance release notes' : 'Generate release notes'}
          isEnhanceConfigurationVersion={isEnhancingConfigurationVersionReleaseNotes}
          onClick={openEnhanceDrawer}
          showBeta
          disabled={disabled}
        />
      </ButtonWrapper>
      <ShiftedDrawer
        title={
          <Badge count="Beta" color={token.orange6} offset={[30, 8]}>
            Release notes: AI enhancement
          </Badge>
        }
        loading={false}
        okText="Replace original text"
        open={drawerVisible}
        isFormDrawer
        onOk={handleReplaceReleaseNotes}
        onCancel={handleCloseDrawer}
        isOkDisabled={enhanceReleaseNotes.status !== 'success'}
      >
        <Form form={form}>
          <StyledMarkdownFormItem
            label="Original release notes"
            field={['releaseNotes']}
            optional={isEnhancingConfigurationVersionReleaseNotes}
            placeholder={RELEASE_NOTES_PLACEHOLDER}
            rules={isEnhancingConfigurationVersionReleaseNotes ? [] : [ReleaseNotesValidator.rule()]}
            shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}
            readonly={enhanceReleaseNotes.isLoading}
          />
          <MarkdownFormItem
            label={
              <StyledEnhancedReleaseNotesLabel padding={isEnhancingConfigurationVersionReleaseNotes ? 6 : 16}>
                Enhanced release notes
              </StyledEnhancedReleaseNotesLabel>
            }
            field={['enhancedReleaseNotes']}
            placeholder="Click 'Generate release notes' button to enhance the release notes."
            isLoading={enhanceReleaseNotes.isLoading}
            isLoadingText="Release notes enhancement loading.."
            readonly
            noInfo
            descriptionMinRows={10}
            descriptionMaxRows={10}
            optional
            shouldUpdate={() => false}
          />
          <EnhanceReleaseNotesButton
            text={`${!isEnhancingConfigurationVersionReleaseNotes ? 'Enhance' : wasGenerated ? 'Regenerate' : 'Generate'} release notes`}
            isEnhanceConfigurationVersion={isEnhancingConfigurationVersionReleaseNotes}
            onClick={() => handleEnhanceReleaseNotes(true)}
            disabled={enhanceReleaseNotes.isLoading}
          />
          <Row>
            <Col>
              <Typography.Text type="danger">* Replacing the original text cannot be undone.</Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography.Text type="danger">** AI-generated content might contain inaccuracies.</Typography.Text>
            </Col>
          </Row>
        </Form>
      </ShiftedDrawer>
    </>
  );
};

export default EnhanceReleaseNotes;
