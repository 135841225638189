import { useState } from 'react';
import { Form, Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { useUpdateToolVersion } from './hooks/useUpdateToolVersion';
import { ReleaseNotesValidator } from '../../../../../../../domain/validation/releaseNotesValidator';
import { VersionValidator } from '../../../../../../../domain/validation/versionValidator';
import { Sha256Validator } from '../../../../../../../domain/validation/sha256Validator';
import { RELEASE_NOTES_PLACEHOLDER } from '../../../../../../../constants/texts';
import { usePermissions } from '../../../../../../session/hooks/usePermissions';

import { ICSPortalComponentIdFormItem, ICS_PORTAL_ID_NAME_KEY } from '../../../../../../shared/components/Forms/ICSPortalComponentIdFormItem';
import { ExpandableMenuItem } from '../../../../../../shared/components/ExpandableMenuItem';
import { MarkdownFormItem } from '../../../../../../shared/components/MarkdownFormItem';
import { BlobUploadFormItem } from '../../../../../../shared/components/BlobUploadFormItem';
import { ShiftedDrawer } from '../../../../../../shared/components/ShiftedDrawer';
import { ChecksumHowToFormLabel } from '../../../../../../shared/components/ChecksumHowToFormLabel';

import type { Tool, ToolVersion } from '../../../../../../../api/engineering/domain/types';
import { EnhanceReleaseNotes } from '../../../EnhanceReleaseNotes/EnhanceReleaseNotes';

export interface EditToolVersionProps {
  detailView?: boolean;
  tool: Tool;
  toolVersion: ToolVersion;
}

export const EditToolVersion = (props: EditToolVersionProps) => {
  const permissions = usePermissions({ toolId: props.tool.id.toString() });
  const [modalVisible, setModalVisible] = useState(false);
  const updateEngineeringToolVersion = useUpdateToolVersion();

  const disabled = props.toolVersion.state !== 'consistent';

  const [form] = Form.useForm();

  const onSubmit = () => {
    form.submit();
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };

  if (!permissions.engineeringSvc$updateToolVersion) return null;

  const handleCancel = () => {
    form.resetFields();
    setModalVisible(false);
  };

  const replaceReleaseNotes = (enhancedReleaseNotes: string) => {
    form.setFieldValue('releaseNotes', enhancedReleaseNotes);
  };

  return (
    <>
      <ExpandableMenuItem
        icon={<EditOutlined />}
        disabled={disabled}
        loading={updateEngineeringToolVersion.isLoading}
        onClick={() => {
          form.setFieldsValue(props.toolVersion);
          setModalVisible(true);
        }}
      >
        {props.detailView ? 'Edit Tool Version' : 'Edit'}
      </ExpandableMenuItem>

      <ShiftedDrawer
        title="Edit version"
        loading={updateEngineeringToolVersion.isLoading}
        open={modalVisible}
        isFormDrawer
        onOk={onSubmit}
        onCancel={handleCancel}
      >
        <Form
          {...layout}
          name="basic"
          labelAlign="left"
          initialValues={props.toolVersion}
          form={form}
          onFinish={(value) => {
            const copy = { ...props.toolVersion, ...value } as ToolVersion;
            updateEngineeringToolVersion
              .mutateAsync([props.tool, copy])
              .then(() => {
                setModalVisible(false);
              })
              .catch(() => {
                /* */
              });
          }}
        >
          <Form.Item label="Version" name="version" rules={[VersionValidator.rule()]}>
            <Input />
          </Form.Item>
          <MarkdownFormItem
            rules={[ReleaseNotesValidator.rule()]}
            label="Release Notes"
            field={['releaseNotes']}
            placeholder={RELEASE_NOTES_PLACEHOLDER}
            shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}
          />
          {permissions.engineeringSvc$postEnhanceReleaseNotes && (
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}>
              {() => (
                <EnhanceReleaseNotes 
                  originalReleaseNotes={form.getFieldValue('releaseNotes')} 
                  onOk={replaceReleaseNotes} 
                  originalForm={form} 
                  type="tool"
                  context={props.tool}
                  version={props.toolVersion}
                />
              )}
            </Form.Item>
          )}
          <BlobUploadFormItem
            allowArtifactoryLinks
            label="Download Link"
            field={['downloadLink']}
            rules={[{ required: true, message: 'Required' }]}
            shouldUpdate={(prevValues, currentValues) => {
              return prevValues.impacts$updateDeploymentLink !== currentValues.impacts$updateDeploymentLink;
            }}
          />
          <Form.Item
            label={<ChecksumHowToFormLabel />}
            name={['sha256']}
            required={false}
            rules={[Sha256Validator.rule(() => form.getFieldValue(['downloadLink']))]}
          >
            <Input />
          </Form.Item>
          <ICSPortalComponentIdFormItem name={[ICS_PORTAL_ID_NAME_KEY]} />
        </Form>
      </ShiftedDrawer>
    </>
  );
};
