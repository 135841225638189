import { Divider, Typography } from 'antd';
import styled from 'styled-components';

const StyledContainer = styled.div`
  max-width: 640px;
  padding: 0 32px;
  margin-top: 15vh;
  margin-bottom: 50px;
`;

export const EccInformation = () => {
  const shortInformation = (
    <>
      <Divider>Disclaimer</Divider>
      <Typography.Paragraph style={{ textAlign: 'justify' }}>
        <b>Export Control:</b> Users from a risk country are also not allowed to access the software. In this case, the EX Business Partner must be contacted
        beforehand. For questions on this requirement reach out to your Export Control Business Partner to be found in COSMOS Organization Charts.
      </Typography.Paragraph>
      <Typography.Paragraph style={{ textAlign: 'justify' }}>
        <b>Data privacy:</b> Data is stored within a data center located in Germany.
      </Typography.Paragraph>
    </>
  );

  return <StyledContainer>{shortInformation}</StyledContainer>;
};
