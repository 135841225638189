import { PacTSPermissions } from '@pacts/permissions-lib';
import { Row, Col, Select, Card, Result, Typography } from 'antd';
import { useMemo } from 'react';
import { Comparator } from '../../../domain';
import { usePermissions } from '../../session/hooks/usePermissions';
import { AppCategoriesEdit } from './entityAdministrationComponents/AppCategoriesEdit';
import { AppCategoriesList } from './entityAdministrationComponents/AppCategoriesList';
import { AppTargetsEdit } from './entityAdministrationComponents/AppTargetsEdit';
import { AppTargetsList } from './entityAdministrationComponents/AppTargetsList';
import { ProjectPhasesEdit } from './entityAdministrationComponents/ProjectPhasesEdit';
import { ProjectPhasesList } from './entityAdministrationComponents/ProjectPhasesList';
import { ProjectRolesEdit } from './entityAdministrationComponents/ProjectRolesEdit';
import { ProjectRolesList } from './entityAdministrationComponents/ProjectRolesList';
import { ProjectTypesEdit } from './entityAdministrationComponents/ProjectTypesEdit';
import { SolutionTypesEdit } from './entityAdministrationComponents/SolutionTypesEdit';
import { SolutionTypesList } from './entityAdministrationComponents/SolutionTypesList';
import { ToolCategoriesEdit } from './entityAdministrationComponents/ToolCategoriesEdit';
import { ToolCategoriesList } from './entityAdministrationComponents/ToolCategoriesList';
import { UserRolesEdit } from './entityAdministrationComponents/UserRolesEdit';
import { UserRolesList } from './entityAdministrationComponents/UserRolesList';
import { useSearchParameter } from '../../../contexts/navigation/hooks/useSearchParameter';
import { ProjectTypesList } from './entityAdministrationComponents/ProjectTypesList';
import { useDocumentTitle } from '../../shared/hooks/useDocumentTitle';

const gutter: [number, number] = [16, 16];
const editableEntities: { key: string; title: string; listComponent: any; editComponent: any; isAllowed: (permissions: PacTSPermissions) => boolean }[] = [
  {
    key: 'solutionTypes',
    title: 'Solution Types',
    listComponent: <SolutionTypesList />,
    editComponent: <SolutionTypesEdit />,
    isAllowed: (p) => {
      return p.engineeringSvc$addProjectSolutionType && p.engineeringSvc$updateProjectSolutionType && p.engineeringSvc$deleteProjectSolutionType;
    }
  },
  {
    key: 'projectTypes',
    title: 'Project Types',
    listComponent: <ProjectTypesList />,
    editComponent: <ProjectTypesEdit />,
    isAllowed: (p) => {
      return p.engineeringSvc$addProjectType && p.engineeringSvc$updateProjectType && p.engineeringSvc$deleteProjectType;
    }
  },
  {
    key: 'projectPhases',
    title: 'Project Phases',
    listComponent: <ProjectPhasesList />,
    editComponent: <ProjectPhasesEdit />,
    isAllowed: (p) => {
      return p.engineeringSvc$addProjectPhase && p.engineeringSvc$updateProjectPhase && p.engineeringSvc$deleteProjectPhase;
    }
  },
  {
    key: 'appCategories',
    title: 'App Categories',
    listComponent: <AppCategoriesList />,
    editComponent: <AppCategoriesEdit />,
    isAllowed: (p) => {
      return p.engineeringSvc$addSoftwareAppCategory && p.engineeringSvc$updateSoftwareAppCategory && p.engineeringSvc$deleteSoftwareAppCategory;
    }
  },
  {
    key: 'appTargets',
    title: 'App Targets',
    listComponent: <AppTargetsList />,
    editComponent: <AppTargetsEdit />,
    isAllowed: (p) => {
      return p.engineeringSvc$addSoftwareAppTarget && p.engineeringSvc$updateSoftwareAppTarget && p.engineeringSvc$deleteSoftwareAppTarget;
    }
  },
  {
    key: 'toolCategories',
    title: 'Tool Categories',
    listComponent: <ToolCategoriesList />,
    editComponent: <ToolCategoriesEdit />,
    isAllowed: (p) => {
      return p.engineeringSvc$addToolCategory && p.engineeringSvc$updateToolCategory && p.engineeringSvc$deleteToolCategory;
    }
  },
  {
    key: 'projectRoles',
    title: 'Project Roles',
    listComponent: <ProjectRolesList />,
    editComponent: <ProjectRolesEdit />,
    isAllowed: (p) => {
      return p.engineeringSvc$addProjectRole && p.engineeringSvc$updateProjectRole && p.engineeringSvc$deleteProjectRole;
    }
  },
  {
    key: 'userRoles',
    title: 'User Roles',
    listComponent: <UserRolesList />,
    editComponent: <UserRolesEdit />,
    isAllowed: (p) => {
      return p.userSvc$addRole && p.userSvc$updateRole && p.userSvc$deleteRole;
    }
  }
];

const sortedEntries = editableEntities.sort((a, b) => Comparator.lexicographicalComparison(a.key, b.key));

export const EntityAdministration = () => {
  const [search, setSearch] = useSearchParameter('t', sortedEntries[0].key);
  const permissions = usePermissions();

  useDocumentTitle('Entity Administration');

  const editable = useMemo(() => {
    return sortedEntries.find((e) => e.key === search) || sortedEntries[0];
  }, [search]);

  const isAllowed = editable.isAllowed(permissions);

  return (
    <Card title={<Typography.Title level={4}>Entity Administration</Typography.Title>}>
      <Row gutter={gutter}>
        <Col span={6}>
          <Row gutter={gutter}>
            <Col span={24}>
              <Select
                style={{ width: '100%' }}
                value={editable.key}
                filterOption={(input, option) => (option?.children?.toString() || '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
                onChange={(sel) => {
                  setSearch(sel);
                }}
              >
                {sortedEntries.map((e) => (
                  <Select.Option key={e.key}>{e.title}</Select.Option>
                ))}
              </Select>
            </Col>
            {isAllowed && <Col span={24}>{editable.listComponent}</Col>}
          </Row>
        </Col>
        <Col span={18}>
          {isAllowed && editable.editComponent}
          {!isAllowed && <Result status="warning" title="Insufficient Permissions" />}
        </Col>
      </Row>
    </Card>
  );
};
