import { PacTSPermissions, PermissionsCalculator } from '@pacts/permissions-lib';
import { Card, Input, Skeleton } from 'antd';
import { useContext, useMemo } from 'react';
import styled from 'styled-components';

import { Comparator } from '../../../domain/extensions/comparison';
import { useProjects } from '../../projects/hooks/useProjects';
import { useTableSearch } from '../../shared/components/TableSearch';
import { useUsers } from '../hooks/useUsers';
import { ProjectMemberRolesDropdown } from './ProjectMemberRolesDropdown';
import { useSession } from '../../../contexts/session/hooks/useSession';
import Table from '../../../contexts/shared/components/Table/Table';
import { useSearchParameter } from '../../../contexts/navigation/hooks/useSearchParameter';
import { PacTSContext } from '../../../state/store';
import { useDocumentTitle } from '../../shared/hooks/useDocumentTitle';

import type { User } from '../../../api/user/domain/users';
import type { Project } from '../../../api/engineering/domain';


const StyledSearch = styled(Input.Search)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const ProjectMemberAdministration = () => {
  const users = useUsers();
  const projects = useProjects();
  const [state] = useContext(PacTSContext);
  const session = useSession(state);
  const search = useTableSearch({ searchValueProvider: 'name', searchParamId: 'name' });
  const [projectFilter, setProjectFilter] = useSearchParameter('project', '');

  useDocumentTitle('Project Member Administration');

  const sorter = (a: User | Project, b: User | Project) => Comparator.lexicographicalComparison(a.name, b.name);

  const filteredProjects = (projects.data || []).filter((p) => {
    if (!projectFilter) {
      return true;
    }
    return p.name.trim().toLowerCase().includes(projectFilter.trim().toLowerCase());
  }).toSorted(sorter);

  const isLoading = users.isLoading || projects.isLoading;
  const isSuccess = users.isSuccess && projects.isSuccess;

  const projectPermissions = useMemo(() => {
    const perms = {} as { [x: string]: PacTSPermissions };
    projects.data?.forEach((p) => {
      perms[p.idProject?.toString() || '-1'] = PermissionsCalculator.calculate(session.userInfo?.permissions ?? {}, {
        projectId: p.idProject.toString()
      }).calculatedPermission;
    });
    return perms;
  }, [projects.data, session.userInfo?.permissions]);

  const columns: any[] = [];
  const userColumn = (orientation: 'left' | 'right') => {
    return {
      title: 'User',
      key: 'name',
      dataIndex: 'name',
      width: 300,
      fixed: orientation,
      ...search,
      sorter
    };
  };
  columns.push(userColumn('left'));
  filteredProjects.forEach((p) => {
    const perms = projectPermissions[p.idProject?.toString() || '-2'];
    if (perms && perms.engineeringSvc$updateProjectMember) {
      columns.push({
        title: p.name,
        key: 'name',
        width: 300,
        render: (u: User) => {
          const projId = p.idProject || -1;
          return <ProjectMemberRolesDropdown projectId={projId.toString()} user={u} />;
        }
      });
    }
  });

  const ProjectSearch = () => (
    <StyledSearch defaultValue={projectFilter} placeholder="Search for Projects" allowClear enterButton="Search" onSearch={(val) => setProjectFilter(val)} />
  );

  const table = (
    <>
      <Card bordered={false} size="small" extra={[<ProjectSearch key="projectSearch" />]}>
        <Table
          size="small"
          scroll={{ x: 'max-content' }}
          columns={columns}
          rowKey={(record: User) => record.id?.toString() || ''}
          dataSource={users.data}
          pagination={{
            showSizeChanger: true
          }}
        />
      </Card>
    </>
  );

  return (
    <>
      {isLoading && <Skeleton active />}
      {isSuccess && <div>{table}</div>}
    </>
  );
};
