import { useState } from 'react';
import { Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ExpandableMenuItem } from '../../../../../shared/components/ExpandableMenuItem';
import { usePermissions } from '../../../../../session/hooks/usePermissions';
import { useCreateSoftwareApp } from './hooks/useCreateSoftwareApp';
import { SemanticVersion } from '../../../../../../domain/versioning/semanticVersion';
import { SoftwareAppCreate } from '../../../../../../api/engineering/domain/types';
import { ShiftedDrawer } from '../../../../../shared/components/ShiftedDrawer';
import { cloneDeep } from 'lodash';
import { AddSoftwareAppForm } from '../../../../../SoftwareApps/components/AddSoftwareApp/AddSoftwareAppForm';

export type AddSoftwareAppProps = {
  projectId: string;
};

const initialSwApp: SoftwareAppCreate = {
  name: '',
  categories: [],
  description: '',
  installationOptions: '',
  latestVersion: {
    version: SemanticVersion.initial().toString(),
    targets: [],
    releaseNotes: ''
  },
  documentationLink: ''
};

export const AddSoftwareApp = (props: AddSoftwareAppProps) => {
  const permissions = usePermissions({ projectId: props.projectId });
  const [modalVisible, setModalVisible] = useState(false);

  const createSoftwareApp = useCreateSoftwareApp();
  const [form] = Form.useForm();

  let okHandler: () => any;
  let resetHandler: () => any;

  return (
    <>
      {permissions.engineeringSvc$addProjectSoftwareApp && (
        <ExpandableMenuItem
          icon={<PlusOutlined />}
          loading={createSoftwareApp.isLoading}
          onClick={() => {
            setModalVisible(true);
          }}
        >
          New Software
        </ExpandableMenuItem>
      )}

      <ShiftedDrawer
        title="New Software"
        open={modalVisible}
        loading={createSoftwareApp.isLoading}
        isFormDrawer
        onOk={() => {
          okHandler();
        }}
        onCancel={() => {
          resetHandler();
          setModalVisible(false);
        }}
        afterOpenChange={(open) => {
          if (!open) form.resetFields();
        }}
      >
        <AddSoftwareAppForm
          initial={cloneDeep(initialSwApp)}
          ok={(ok) => {
            okHandler = ok;
          }}
          reset={(cancel) => {
            resetHandler = cancel;
          }}
          form={form}
          onFinish={async (ps) => {
            return new Promise((resolve, reject) => {
              createSoftwareApp
                .mutateAsync([props.projectId, ps])
                .then(() => {
                  setModalVisible(false);
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            });
          }}
        />
      </ShiftedDrawer>
    </>
  );
};
