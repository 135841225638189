import { Drawer as AntDrawer, Button, Row, Space } from 'antd';
import type { DrawerProps } from 'antd';

type Props = {
  /**
   * If the Drawer is a Form Drawer, two additional buttons
   * for "Cancel" and "Save" will be added to the footer.
   */
  isFormDrawer?: boolean;
  /**
   * This property only applies, if `isFormDrawer` is `true`.
   * A callback that will be executed when the "Save" button is clicked.
   */
  onOk?: () => void;
  /**
   * This property only applies, if `isFormDrawer` is `true`.
   * A callback that will be executed when the "Cancel" button
   * is clicked, as well as when the drawer is closed.
   */
  onCancel?: () => void;
  /**
   * Do not destroy the drawer on close
   */
  doNotDestroyOnClose?: boolean;
  /**
   * Text for the OK button
   */
  okText?: string;
  /**
   * Disable state for the OK button
   */
  isOkDisabled?: boolean;
};

export const ShiftedDrawer = ({ 
  isFormDrawer, onOk, onCancel, onClose, okText, isOkDisabled, width, doNotDestroyOnClose, ...rest 
}: Omit<DrawerProps, 'destroyOnClose' | 'keyboard'> & Props) => {
  const styles: DrawerProps['styles'] = {
    ...rest.styles,
    wrapper: { top: 64 }
  };

  let footer = undefined;

  if (!!isFormDrawer) {
    footer = (
      <Row justify="end">
        <Space>
          <Button onClick={onCancel}>Cancel</Button>
          <Button type="primary" onClick={onOk} disabled={isOkDisabled}>
            {okText || 'Save'}
          </Button>
        </Space>
      </Row>
    );
  }

  return (
    <AntDrawer
      {...rest}
      destroyOnClose={!doNotDestroyOnClose}
      onClose={isFormDrawer ? onCancel : onClose}
      width={width ?? '830px'}
      styles={styles}
      footer={rest.footer ?? footer}
      keyboard={false}
    />
  );
};
