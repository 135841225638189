import { useState, useEffect } from 'react';
import { Button, Form } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { EditSoftwareAppForm } from './EditSoftwareAppForm';
import { useUpdateCommonSoftwareApp } from './hooks/useUpdateCommonSoftwareApp';
import { SoftwareApp } from '../../../../../../api/engineering/domain/types';
import { ShiftedDrawer } from '../../../../../shared/components/ShiftedDrawer';
import { usePermissions } from '../../../../../../contexts/session/hooks/usePermissions';
import { useCommonSoftwareAppVersions } from '../../SoftwareItemVersions/components/SoftwareItemVersionsList/hooks/useCommonSoftwareAppVersions';

export const EditCommonSoftwareApp = (props: { softwareApp: SoftwareApp }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const updateSoftwareApp = useUpdateCommonSoftwareApp();
  const versions = useCommonSoftwareAppVersions(props.softwareApp.idSoftwareApp?.toString() || '');
  const permission = usePermissions({
    softwareAppId: props.softwareApp.idSoftwareApp?.toString()
  });

  let okHandler: () => any;
  let resetHandler: () => any;
  const [form] = Form.useForm();

  useEffect(() => {
    if (updateSoftwareApp.isSuccess) {
      setModalVisible(false);
      form.resetFields();
    }
  }, [form, updateSoftwareApp.isSuccess]);

  if (!permission.engineeringSvc$updateCommonSoftwareApp) return null;

  return (
    <>
      <Button
        icon={<EditOutlined />}
        loading={updateSoftwareApp.isLoading}
        onClick={() => {
          setModalVisible(true);
        }}
      >
        Edit
      </Button>

      <ShiftedDrawer
        title="Edit software"
        loading={updateSoftwareApp.isLoading}
        open={modalVisible}
        isFormDrawer
        onOk={() => {
          okHandler();
        }}
        onCancel={() => {
          resetHandler();
          setModalVisible(false);
        }}
      >
        <EditSoftwareAppForm
          initial={props.softwareApp}
          ok={(ok) => {
            okHandler = ok;
          }}
          reset={(cancel) => {
            resetHandler = cancel;
          }}
          form={form}
          onFinish={async (ps) => {
            return new Promise((resolve, reject) => {
              updateSoftwareApp
                .mutateAsync([ps])
                .then(() => {
                  setModalVisible(false);
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            });
          }}
          versions={versions.data || []}
        />
      </ShiftedDrawer>
    </>
  );
};
