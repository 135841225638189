import { Form, Input, FormInstance } from 'antd';
import { ShiftedDrawer } from '../../shared/components/ShiftedDrawer';
import styled from 'styled-components';
import { Environment } from '../../../api';
import { useAddDeploymentEnvironment } from '../hooks/useAddDeploymentEnvironment';
import { useUpdateDeploymentEnvironments } from '../hooks/useUpdateDeploymentEnvironments';

const StyledTextArea = styled(Input.TextArea)`
  min-height: 150px !important;
  max-height: 450px !important;
`;

export interface AddEditEnvironmentFormProps {
  open: boolean;
  type: 'create' | 'update';
}

interface AddEditEnvironmentDrawerProps {
  projectId: number;
  formModalProps: AddEditEnvironmentFormProps;
  formValues: Record<string, any> | null;
  form: FormInstance;
  setFormModalProps: (formProps: AddEditEnvironmentFormProps) => void;
  setFormValues: (formValues: Record<string, any> | null) => void;
  closeModal: () => void;
  onFinishCallback?: () => void;
}

export const AddEditEnvironmentDrawer = ({
  projectId,
  formModalProps,
  formValues,
  form,
  setFormModalProps,
  setFormValues,
  closeModal,
  onFinishCallback
}: AddEditEnvironmentDrawerProps) => {
  const addDepEnv = useAddDeploymentEnvironment();
  const updateDepEnv = useUpdateDeploymentEnvironments();

  const okHandler = () => {
    form.submit();
  };

  const cancelHandler = () => {
    form.resetFields();
    setFormModalProps({ ...formModalProps, open: false });
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };

  const handleFinish = async (v: Environment) => {
    if (formModalProps.type === 'create') {
      addDepEnv
        .mutateAsync([projectId, v.name, v.description])
        .then(() => {
          closeModal();
          form.resetFields();

          if (onFinishCallback) {
            onFinishCallback();
          }
        })
        .catch(() => {});
    } else {
      updateDepEnv
        .mutateAsync([projectId, formValues?.id, formValues?.mvccId, v.name, v.description])
        .then(() => {
          closeModal();
          form.resetFields();
        })
        .catch(() => {});
    }

    setFormValues(null);
  };

  return (
    <ShiftedDrawer
      title={`${formModalProps.type === 'create' ? 'Create new' : 'Edit'} environment`}
      open={formModalProps.open}
      isFormDrawer
      onCancel={cancelHandler}
      onOk={okHandler}
    >
      <Form {...layout} name="add__deployment_environment_form" labelAlign="left" form={form} onFinish={handleFinish}>
        <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Required' }]}>
          <Input />
        </Form.Item>

        <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Required' }]}>
          <StyledTextArea />
        </Form.Item>
      </Form>
    </ShiftedDrawer>
  );
};
