export enum MetricClickIds {
  openInImpacts = 'open_in_impacts',
  componentDetails = 'component_details',
  componentVersions = 'component_versions',
  projectKPIsOpen = 'project_kpis_open',
  genAIGenerateReleaseNotes = 'genai_generate_release_notes',
  genAIRegenerateReleaseNotes = 'genai_regenerate_release_notes',
  genAIReplaceOriginalText = 'genai_replace_original_text',
  genAICancelEnhancement = 'genai_cancel_enhancement',
  reportOpen = 'report_open',
  reportDownload = 'report_download',
  reportShare = 'report_share',
  reportSelectRevision = 'report_select_revision',
  aisForceRigidCalculate = 'aisforce_rigid_calculate',
  aisForceFlexibleCalculate = 'aisforce_flexible_calculate',
  aisForceRigidGenerateReport = 'aisforce_rigid_generate_report',
  aisForceFlexibleGenerateReport = 'aisforce_flexible_generate_report',
  aisForceRigidAmpacityCalculate = 'aisforce_rigid_ampacity_calculate',
  aisForceRigidAmpacityReport = 'aisforce_rigid_ampacity_report',
  aisForceFlexibleAmpacityCalculate = 'aisforce_flexible_ampacity_calculate',
  aisForceFlexibleAmpacityReport = 'aisforce_flexible_ampacity_report'
}

export enum MetricViewIds {
  notFound = 'not_found',
  reportDetails = 'report_details',
  notificationList = 'notification_list',
  toolsCompareList = 'tools_compare_list',
  appsCompareList = 'apps_compare_list',
  toolsProjectIntegration = 'tools_project_integration',
  appsProjectIntegration = 'apps_project_integration'
}
