import { Button, Tooltip } from 'antd';
import { Project, SoftwareApp, SoftwareAppVersion, Tool, ToolVersion } from '../../../api/engineering';
import { OpenComponentArgs, ToolManagerArgs, ToolManagerLink } from '../../../domain/toolmanager/toolmanagerArgs';
import { PacTSIcon } from '../../../contexts/shared/components/icons/PacTSIcon';
import useSubmitMetric from '../../../contexts/metrics/hooks/useMetrics';
import { MetricClickIds } from '../../../contexts/metrics/constants';
import { SoftwareComponent } from '../../../api/deployment/domain/deployments';
import { useArtifactoryLinkPreFlight } from '../../../contexts/shared/components/Download/hooks/useArtifactoryLinkPreFlightCheck';
import { useComponentVersion } from '../hooks/useComponentVersion';
import { useEffect, useState } from 'react';

const convertArgs = (
  artifact: Tool | SoftwareApp | null,
  version: ToolVersion | SoftwareAppVersion | null,
  component: SoftwareComponent,
  project?: Project
): ToolManagerArgs | null => {
  const toolVersion = !!(version as ToolVersion).idToolVersion ? (version as ToolVersion) : undefined;
  const appVersion = !!(version as SoftwareAppVersion).idSoftwareAppVersion ? (version as SoftwareAppVersion) : undefined;
  const mode = !!toolVersion ? 'tool' : !!appVersion ? 'app' : 'undefined';

  let openArgs: OpenComponentArgs;

  if (mode === 'tool') {
    const tool = artifact as Tool;
    const matchingTarget = component.targets?.[0];

    openArgs = {
      component: {
        name: tool.name,
        version: toolVersion!.version,
        category: tool.category.map((category) => category.name).join(', '),
        id: tool.id.toString(),
        scope: 'common',
        type: 'tool',
        targets: [
          {
            downloadLink: toolVersion!.downloadLink,
            targetId: 0,
            // empty string since the target is not available for tools
            targetName: '',
            // keep the sha256 from the provided component to ensure integrity
            // fall back to version sha256 in case not available (for old data)
            sha256: matchingTarget?.sha256 ?? toolVersion!.sha256
          }
        ],
        versionId: toolVersion!.idToolVersion.toString(),
        context: project ? { projectId: project.idProject.toString(), projectName: project.name } : undefined
      }
    };
  } else if (mode === 'app') {
    const app = artifact as SoftwareApp;
    openArgs = {
      component: {
        name: app.name,
        version: appVersion!.version,
        category: app.categories.map((category) => category.name).join(', '),
        id: app.idSoftwareApp.toString(),
        scope: project ? 'project' : 'common',
        type: 'app',
        targets: appVersion!.targets.map((target) => {
          const matchingTarget = component.targets?.find((componentTarget) => componentTarget.id === target.target.idTarget);

          return {
            downloadLink: target.downloadLink,
            targetId: target.target.idTarget,
            targetName: target.target.name,
            // keep the sha256 from the provided component to ensure integrity
            // fall back to version sha256 in case not available (for old data)
            sha256: matchingTarget?.sha256 ?? target.sha256
          };
        }),
        versionId: appVersion!.idSoftwareAppVersion.toString(),
        context: project ? { projectId: project.idProject.toString(), projectName: project.name } : undefined
      }
    };
  } else {
    return null;
  }

  const args: ToolManagerArgs = {
    action: 'openComponent',
    payload: openArgs
  };

  return args;
};

export const SoftwareComponentOpenInToolmanagerButton = (props: { project: Project; component: SoftwareComponent }) => {
  const metrics = useSubmitMetric();

  // enable the loading button
  // this is done on click to prevent loading
  // unnecessary data
  const [enabled, setEnabled] = useState(false);

  const isProjectApp = props.component.scope === 'project' && props.component.type === 'app';

  // when opening in impacts, the full download path is required for the open arguments
  // hence loading the data is always required
  // if the data has been loaded before by the gateway, the data is taken from cache
  const { query: queriedVersion } = useComponentVersion(props.project.idProject, props.component, enabled);

  const args = queriedVersion.data
    ? convertArgs(queriedVersion.data.component, queriedVersion.data.version, props.component, isProjectApp ? props.project : undefined)
    : undefined;

  const canDownload = useArtifactoryLinkPreFlight(queriedVersion.data?.version ?? null);

  const openLink = () => {
    if (!queriedVersion.data) {
      return;
    }
    if (!args) {
      return;
    }

    // if cannot be downloaded, do nothing
    if (!canDownload) {
      return;
    }

    metrics.submitClick({ operationId: MetricClickIds.openInImpacts }).withSoftwareComponent(props.component, props.project);

    ToolManagerLink.open(args);
  };

  useEffect(() => {
    // if data is not loaded yet, do nothing
    if (!queriedVersion.isSuccess) {
      return;
    }

    // if loading is disabled, do nothing
    if (!enabled) {
      return;
    }

    openLink();

    // ignore the open function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queriedVersion.isSuccess, enabled]);

  const onClick = () => {
    // on click load
    // if load has not been set before, the use effect
    // hook will trigger opening the new page
    if (!enabled) {
      setEnabled(true);
      return;
    }

    // if the user clicks while loading
    // ignore the click
    if (!queriedVersion.isSuccess) {
      return;
    }
    // on click always call open
    // to work on second click
    openLink();
  };

  return (
    <Tooltip title="Open in ImPacTS">
      <Button disabled={!canDownload} loading={queriedVersion.isLoading} icon={<PacTSIcon disabled={!canDownload} />} type="text" onClick={onClick} />
    </Tooltip>
  );
};
