import { useState, useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useCreateToolVersion } from './hooks/useCreateToolVersion';
import { ReleaseNotesValidator } from '../../../../../../../domain/validation/releaseNotesValidator';
import { VersionValidator } from '../../../../../../../domain/validation/versionValidator';
import { Sha256Validator } from '../../../../../../../domain/validation/sha256Validator';
import { RELEASE_NOTES_PLACEHOLDER } from '../../../../../../../constants/texts';
import { usePermissions } from '../../../../../../session/hooks/usePermissions';

import { ICSPortalComponentIdFormItem, ICS_PORTAL_ID_NAME_KEY } from '../../../../../../shared/components/Forms/ICSPortalComponentIdFormItem';
import { MarkdownFormItem } from '../../../../../../shared/components/MarkdownFormItem';
import { SemanticVersion } from '../../../../../../../domain/versioning/semanticVersion';
import { BlobUploadFormItem } from '../../../../../../shared/components/BlobUploadFormItem';
import { ShiftedDrawer } from '../../../../../../shared/components/ShiftedDrawer';
import { ChecksumHowToFormLabel } from '../../../../../../shared/components/ChecksumHowToFormLabel';

import type { Tool, ToolVersion, ToolVersionCreate } from '../../../../../../../api/engineering/domain/types';
import { EnhanceReleaseNotes } from '../../../EnhanceReleaseNotes/EnhanceReleaseNotes';

const initialEngineeringToolVersion: ToolVersionCreate = {
  version: '',
  releaseNotes: '',
  downloadLink: ''
};

export interface AddToolVersionProps {
  tool: Tool;
  detailView?: boolean;
}

export const AddToolVersion = (props: AddToolVersionProps) => {
  const permissions = usePermissions({ toolId: props.tool.id.toString() });
  const [modalVisible, setModalVisible] = useState(false);
  const createEngineeringTool = useCreateToolVersion();

  const [form] = Form.useForm();

  const onSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    if (createEngineeringTool.isSuccess) {
      setModalVisible(false);
      form.resetFields();
    }
  }, [createEngineeringTool.isSuccess, form]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };

  if (!permissions.engineeringSvc$addToolVersion) return null;

  const handleCancel = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const replaceReleaseNotes = (enhancedReleaseNotes: string) => {
    form.setFieldValue('releaseNotes', enhancedReleaseNotes);
  };

  return (
    <>
      <Button
        icon={<PlusOutlined />}
        loading={createEngineeringTool.isLoading}
        onClick={() => {
          setModalVisible(true);
        }}
        type="default"
        style={props.detailView ? { float: 'right' } : {}}
      >
        New version
      </Button>

      <ShiftedDrawer
        title="New version"
        loading={createEngineeringTool.isLoading}
        open={modalVisible}
        isFormDrawer
        onOk={onSubmit}
        onCancel={handleCancel}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{
            ...initialEngineeringToolVersion,
            version: SemanticVersion.fromUnsafeString(props.tool.latestVersion?.version)
              .increment()
              .toString({ hideSuffix: true })
          }}
          form={form}
          onFinish={(value) => {
            const copy = { ...initialEngineeringToolVersion, ...value } as ToolVersion;
            createEngineeringTool.mutate([props.tool, copy]);
          }}
          labelAlign="left"
        >
          <Form.Item label="Version" name="version" rules={[VersionValidator.rule()]}>
            <Input />
          </Form.Item>
          <MarkdownFormItem
            label="Release Notes"
            field={['releaseNotes']}
            rules={[ReleaseNotesValidator.rule()]}
            placeholder={RELEASE_NOTES_PLACEHOLDER}
            shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}
          />
          {permissions.engineeringSvc$postEnhanceReleaseNotes && (
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}>
              {() => (
                <EnhanceReleaseNotes originalReleaseNotes={form.getFieldValue('releaseNotes')} onOk={replaceReleaseNotes} originalForm={form} type="tool" context={props.tool} />
              )}
            </Form.Item>
          )}
          <BlobUploadFormItem
            label="Download Link"
            field={['downloadLink']}
            rules={[{ required: true, message: 'Required' }]}
            shouldUpdate={(prevValues, currentValues) => {
              return prevValues.impacts$updateDeploymentLink !== currentValues.impacts$updateDeploymentLink;
            }}
          />
          <Form.Item
            label={<ChecksumHowToFormLabel />}
            name={['sha256']}
            required={false}
            rules={[Sha256Validator.rule(() => form.getFieldValue(['downloadLink']))]}
          >
            <Input />
          </Form.Item>
          <ICSPortalComponentIdFormItem name={[ICS_PORTAL_ID_NAME_KEY]} />
        </Form>
      </ShiftedDrawer>
    </>
  );
};
