import { FullWidthLayout } from '../../layout/components/FullWidthLayout';
import { Segmented } from '../../../contexts/shared/components/Segmented/Segmented.styled';
import { DatePicker, Space, Typography } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';
import { AllArtifactResources } from './AllArtifactResources';
import { AllMembershipResources } from './AllMembershipResources';
import styled from 'styled-components';
import { AllProjectResources } from './AllProjectResources';
import { SyncProjectRolesButton } from './SyncProjectRolesButton';
import { useSearchParameter } from '../../../contexts/navigation/hooks';
import { useMemo } from 'react';
import { getPastDate, getAfterDate } from '../../../contexts/shared/components/formatDate';
import { useDocumentTitle } from '../../shared/hooks/useDocumentTitle';
import dayjs, { type Dayjs } from 'dayjs';

export enum SegmentedTabsEnum {
  Projects = 'projects',
  Memberships = 'memberships',
  Artifacts = 'artifacts'
}

const TabLabel = styled(Typography.Text)`
  margin: 0;
  padding: 0;
`;

const LabelContainer = styled.div`
  max-width: 164px;
  margin-bottom: 4px;
`;

const StyledRangePicker = styled(DatePicker.RangePicker)`
  margin-left: 24px;
`;

const AllResources = () => {
  const [selectedTabRaw, setSelectedTab] = useSearchParameter('tab', SegmentedTabsEnum.Projects, true, false);
  const [startTimestampIso, setStartTimestampIso] = useSearchParameter('startTimestamp', getPastDate(7).startOf('day').toISOString());
  const [endTimestampIso, setEndTimestampIso] = useSearchParameter('endTimestamp', getAfterDate(1).startOf('day').toISOString());
  const selectedRange: [Dayjs, Dayjs] = useMemo(() => [dayjs(startTimestampIso), dayjs(endTimestampIso)], [startTimestampIso, endTimestampIso]);
  const selectedTab = selectedTabRaw as SegmentedTabsEnum;

  useDocumentTitle('PacTS Formation Resources');

  const setSelectedRange = (dates: [Dayjs, Dayjs]) => {
    setStartTimestampIso(dates[0].toISOString());
    setEndTimestampIso(dates[1].toISOString());
  };

  const handleTabChange = (newValue: SegmentedValue) => {
    setSelectedTab(newValue as SegmentedTabsEnum);
  };

  const handleTimeRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    const datesNotEmpty = dates?.every((date) => !!date) || false;

    if (datesNotEmpty) {
      setSelectedRange(dates as [Dayjs, Dayjs]);
    }
  };

  // In order to fetch including the date of start and including the date of end we need to pass the midnight timestamp of the next day
  const parsedSelectedRange: [Dayjs, Dayjs] = [selectedRange[0].startOf('day'), selectedRange[1].add(1, 'day').startOf('day')];
  const contentBySelectedTab = {
    [SegmentedTabsEnum.Projects]: <AllProjectResources pickedRange={parsedSelectedRange} />,
    [SegmentedTabsEnum.Memberships]: <AllMembershipResources pickedRange={parsedSelectedRange} />,
    [SegmentedTabsEnum.Artifacts]: <AllArtifactResources pickedRange={parsedSelectedRange} />
  };

  return (
    <FullWidthLayout padding title="PacTS Formation Resources" extra={[<SyncProjectRolesButton key="sync-project-roles" />]}>
      <Segmented
        onChange={handleTabChange}
        value={selectedTab}
        options={[
          {
            label: (
              <Space size={0}>
                <LabelContainer>
                  <TabLabel ellipsis>Projects</TabLabel>
                </LabelContainer>
              </Space>
            ),
            value: SegmentedTabsEnum.Projects
          },
          {
            label: (
              <Space size={0}>
                <LabelContainer>
                  <TabLabel ellipsis>Artifacts</TabLabel>
                </LabelContainer>
              </Space>
            ),
            value: SegmentedTabsEnum.Artifacts
          },
          {
            label: (
              <Space size={0}>
                <LabelContainer>
                  <TabLabel ellipsis>Memberships</TabLabel>
                </LabelContainer>
              </Space>
            ),
            value: SegmentedTabsEnum.Memberships
          }
        ]}
      />
      <StyledRangePicker maxDate={dayjs()} value={selectedRange} onChange={handleTimeRangeChange} showTime={false} allowClear={false} />
      {contentBySelectedTab[selectedTab]}
    </FullWidthLayout>
  );
};

export default AllResources;
