import { useState, useEffect } from 'react';
import { Form, Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { useUpdateCommonSoftwareAppVersion } from './hooks/useUpdateCommonSoftwareAppVersion';

import { ReleaseNotesValidator } from '../../../../../../../domain/validation/releaseNotesValidator';
import { VersionValidator } from '../../../../../../../domain/validation/versionValidator';
import { Sha256Validator } from '../../../../../../../domain/validation/sha256Validator';

import { ExpandableMenuItem } from '../../../../../../shared/components/ExpandableMenuItem';
import { MarkdownFormItem } from '../../../../../../shared/components/MarkdownFormItem';
import { BlobUploadFormItem } from '../../../../../../shared/components/BlobUploadFormItem';
import { ICSPortalComponentIdFormItem, ICS_PORTAL_ID_NAME_KEY } from '../../../../../../shared/components/Forms/ICSPortalComponentIdFormItem';
import { ShiftedDrawer } from '../../../../../../shared/components/ShiftedDrawer';
import React from 'react';
import { ChecksumHowToFormLabel } from '../../../../../../shared/components/ChecksumHowToFormLabel';
import { RELEASE_NOTES_PLACEHOLDER } from '../../../../../../../constants/texts';
import { usePermissions } from '../../../../../../session/hooks/usePermissions';

import type { SoftwareApp, SoftwareAppVersion } from '../../../../../../../api/engineering/domain/types';
import { EnhanceReleaseNotes } from '../../../EnhanceReleaseNotes/EnhanceReleaseNotes';

export const EditCommonSoftwareAppVersion = (props: { parentApp: SoftwareApp; softwareAppVersion: SoftwareAppVersion }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const updateSoftwareAppVersion = useUpdateCommonSoftwareAppVersion();
  const permission = usePermissions({
    softwareAppId: props.parentApp.idSoftwareApp?.toString(),
    idSoftwareAppVersion: props.softwareAppVersion.idSoftwareAppVersion.toString()
  });
  const [form] = Form.useForm();

  const disabled = props.softwareAppVersion.state !== 'consistent';

  const onSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    if (updateSoftwareAppVersion.isSuccess) {
      setModalVisible(false);
      form.resetFields();
    }
  }, [form, updateSoftwareAppVersion.isSuccess]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };

  if (!permission.engineeringSvc$updateCommonSoftwareAppVersion) return null;

  const replaceReleaseNotes = (enhancedReleaseNotes: string) => {
    form.setFieldValue('releaseNotes', enhancedReleaseNotes);
  };

  return (
    <>
      <ExpandableMenuItem
        icon={<EditOutlined />}
        loading={updateSoftwareAppVersion.isLoading}
        disabled={disabled}
        onClick={() => {
          setModalVisible(true);
          // Make sure that if the drawer is re-opened after having updated
          // the inputs before, the new values are being shown.
          form.setFieldsValue(props.softwareAppVersion);
        }}
      >
        Edit
      </ExpandableMenuItem>

      <ShiftedDrawer
        title="Edit version"
        loading={updateSoftwareAppVersion.isLoading}
        open={modalVisible}
        isFormDrawer
        onOk={onSubmit}
        onCancel={() => {
          setModalVisible(false);
          form.resetFields();
        }}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{ ...props.softwareAppVersion }}
          form={form}
          onFinish={(value) => {
            const copy = { ...value };
            updateSoftwareAppVersion.mutate([props.parentApp.idSoftwareApp?.toString(), Object.assign(props.softwareAppVersion, copy)]);
          }}
          labelAlign="left"
        >
          <Form.Item label="Version" name="version" required rules={[VersionValidator.rule()]}>
            <Input />
          </Form.Item>
          <MarkdownFormItem
            rules={[ReleaseNotesValidator.rule()]}
            label="Release Notes"
            field={['releaseNotes']}
            placeholder={RELEASE_NOTES_PLACEHOLDER}
            shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}
          />
          {permission.engineeringSvc$postEnhanceReleaseNotes && (
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}>
              {() => (
                <EnhanceReleaseNotes 
                  originalReleaseNotes={form.getFieldValue('releaseNotes')} 
                  onOk={replaceReleaseNotes} 
                  originalForm={form} 
                  type="app"
                  context={props.parentApp}
                  version={props.softwareAppVersion}
                />
              )}
            </Form.Item>
          )}
          <Form.List name="targets">
            {(fields) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <React.Fragment key={index}>
                      <BlobUploadFormItem
                        allowArtifactoryLinks
                        label={`Download Link ${form.getFieldValue(['targets', index, 'target', 'name'])}`}
                        field={['targets', index, 'downloadLink']}
                        relativeFieldInList={[field.key.toString(), 'downloadLink']}
                        rules={[{ required: true, message: 'Required' }]}
                        shouldUpdate={(prevValues, currentValues) => {
                          return prevValues.targets[index].impacts$updateDeploymentLink !== currentValues.targets[index].impacts$updateDeploymentLink;
                        }}
                      />
                      <Form.Item
                        label={<ChecksumHowToFormLabel target={form.getFieldValue(['targets', index, 'target', 'name'])} />}
                        name={[index, 'sha256']}
                        required={false}
                        rules={[Sha256Validator.rule(() => form.getFieldValue(['targets', index, 'downloadLink']))]}
                      >
                        <Input />
                      </Form.Item>

                      <ICSPortalComponentIdFormItem target={form.getFieldValue(['targets', index, 'target', 'name'])} name={[index, ICS_PORTAL_ID_NAME_KEY]} />
                    </React.Fragment>
                  ))}
                </div>
              );
            }}
          </Form.List>
        </Form>
      </ShiftedDrawer>
    </>
  );
};
