import { useEffect } from "react"
import { useEnvironment } from "../../navigation/hooks";

export function useDocumentTitle(extra?: string) {
    const envText = useEnvironment().toUpperCase();
    const base = envText ? `PacTS [${envText}]` : 'PacTS';

    useEffect(() => {
        document.title = extra ? `${base} | ${extra}` : base;

        return () => {
            document.title = base;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extra]);
}