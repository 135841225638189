import { Typography, Space, Col, Row, Grid, Flex } from 'antd';
import { DevPanel } from '../../development/components/DevPanel';
import { PacTSTextIcon } from '../../shared/components/icons/PacTSTextIcon';
import { useTheme, THEMES } from '../../theme/ThemeProvider';
import { useEnvironment } from '../hooks/useEnvironment';
import { useStyles } from '../../theme/useStyles';
import styled from 'styled-components';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';
import { SEIcon } from '../../../contexts/shared/components/SEIcon';
import { Link } from 'react-router-dom';
import { useDocumentTitle } from '../../shared/hooks/useDocumentTitle';

const StyledIcon = styled(PacTSTextIcon)`
  display: table-cell;
  cursor: pointer;
`;

const { Title } = Typography;

const DevPanelColumn = styled(Col)`
  margin-top: 8px;
`;

const IconWrapper = styled.div<{ addMargin?: boolean }>`
  line-height: 0;
  ${({ addMargin }) => (addMargin ? 'margin-right: 8px;' : '')}
`;

const { useBreakpoint } = Grid;

export const TopMenuTitle = () => {
  const br = useBreakpoint();
  const [theme] = useTheme();
  const pactsEnvironment = useEnvironment();
  const envText = pactsEnvironment.toUpperCase();
  const hasEnvText = envText !== '';
  const styles = useStyles();
  const permissions = usePermissions();

  const isLg = br.lg === true;
  const isLoginPage = window.location.pathname !== '/login';
  const isLightTheme = theme === THEMES.LIGHT;

  const darkIcon = !isLoginPage && !isLg && isLightTheme;

  useDocumentTitle();

  return (
    <>
      <Space size={4} style={{ float: 'left', marginRight: '1rem', height: '64px' }}>
        <Flex gap={10}>
          <IconWrapper>
            <Link to="/" style={{ display: 'flex', height: '100%' }}>
              <SEIcon style={{ fontSize: styles.fontSizeHeading3, fill: darkIcon ? 'black' : 'white' }} />
            </Link>
          </IconWrapper>
          <IconWrapper addMargin>
            <Link to="/">
              <StyledIcon isLoggedIn={isLoginPage} showDarkIcon={darkIcon} />
            </Link>
          </IconWrapper>
        </Flex>
        <Row align="middle">
          {hasEnvText && (
            <Col flex="none">
              <Title style={{ color: styles.colorWarning, margin: 0 }} level={3}>
                {envText}
              </Title>
            </Col>
          )}
        </Row>
        {permissions.all$unrestrictedAdministration && (
          <DevPanelColumn flex="none" xs={0} xl={24}>
            <DevPanel />
          </DevPanelColumn>
        )}
      </Space>
    </>
  );
};
